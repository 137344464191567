import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import populateGatsbyImage from '../utils/populateGatsbyImage';
import GenericSection from './generic-section';
import IconTextList from './icon-text-list';

const iconListData = [
  {
    icon: 'iconPeople',
    title: '人才缺乏',
    desc: '没时间没经验 人才缺乏效率低'
  }, {
    icon: 'iconLocate',
    title: '不懂定位',
    desc: '没内容没规划，不懂定位及品牌推广'
  }, {
    icon: 'iconAd',
    title: '推广困难',
    desc: '没经验没效果，推广手段风险大'
  }, {
    icon: 'iconHospital',
    title: '缺乏临床思维',
    desc: '不够严谨和专业，沟通难'
  }, {
    icon: 'iconPatient',
    title: '缺乏患者思维',
    desc: '难以和患者建立信任和互动'
  }
];

export default function NewMediaProblemsSection() {
  const data = useStaticQuery(graphql`
    query {
      iconPeople: file(relativePath: { eq: "icon-people.png" }) {
        childImageSharp {
          fixed(width: 85, height: 85) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      iconLocate: file(relativePath: { eq: "icon-locate.png" }) {
        childImageSharp {
          fixed(width: 85, height: 85) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      iconAd: file(relativePath: { eq: "icon-ad.png" }) {
        childImageSharp {
          fixed(width: 85, height: 85) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      iconHospital: file(relativePath: { eq: "icon-hospital.png" }) {
        childImageSharp {
          fixed(width: 85, height: 85) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      iconPatient: file(relativePath: { eq: "icon-patient.png" }) {
        childImageSharp {
          fixed(width: 85, height: 85) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
    }
  `);

  return <>
    <GenericSection
      title="新媒体运营五大难题"
      desc="医疗健康类新媒体运营难：没定位、没规划、没时间、没效果，缺乏临床思维，不够专业；缺乏患者思维，难以建立信任和互动。"
      style={{ paddingLeft: 80, paddingRight: 80 }}
    >
      <IconTextList list={populateGatsbyImage(data, iconListData, 'icon')} />
    </GenericSection>
  </>
}
