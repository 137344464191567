import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import GenericSection from '../components/generic-section';
import NewMediaImageList from '../components/new-media-image-list';
import NewMediaProblemsSection from '../components/new-media-problems-section';
import PageLayout from "../components/page-layout";
import SimpleBanner from '../components/simple-banner';
import populateGatsbyImage from '../utils/populateGatsbyImage';
import SEO from '../components/seo';

const advantageData = [
  {
    title: '医药背景运营团队',
    desc: <>医学内容运营，专业有保障，沟通更顺畅，<br />健康科普知识深入浅出，选题策划更科学。</>,
    image: 'image1',
  }, {
    title: '定制化运营策略',
    desc: <>量身定制合适微信运营策略，为您搭建<br />个性化的微信公众号平台系统定期数据报表汇报</>,
    image: 'image2',
  }, {
    title: '根据定位进行用户互动',
    desc: <>个性化互动平台，自定义特色菜单，<br />有趣的微活动，超强体验微官网，让用户轻松记住。</>,
    image: 'image3'
  }
];

export default function NewMediaPage({ data }) {
  const { banner, social1, social2 } = data;

  return <PageLayout pageKey="/new-media">
    <SEO title="新媒体代运营" />

    <SimpleBanner
      image={banner.childImageSharp.fixed}
      title="新媒体代运营"
      contentStyle={{ backgroundColor: 'rgba(38, 38, 38, 0.4)' }}
    />
    <NewMediaProblemsSection />
    <GenericSection
      title="为您定制个性化运营方案"
      desc="具有医药背景的互联网运营团队，帮助客户解决医疗健康类新媒体的运营难题，一站式解决方案，提高市场化竞争力。"
      detail="根据医疗健康行业特殊性及客户自身的定位，专业剖析品牌特性分析人群画像，为客户定制专属的新媒体运营推广方案，帮助客户更好的进行品牌构建、互联网传播等，建立用户信任。"
      style={{ backgroundColor: '#f0f0f0' }}
    />
    <Image fixed={social1.childImageSharp.fixed} />

    <GenericSection
      title="多样化媒体平台，全新媒体覆盖"
      desc="上百家行业内优质医疗健康互联网媒体，覆盖全新媒体（公众号、微博、短视频各行业大V）等多样化的媒介资源。"
      detail="高质量内容创意：为您的公众号分析定位，制定运营规划，进行内容运营，线上创意互动，定制品牌形象，构建品牌形象，建立医患信任。"
    />
    <Image fixed={social2.childImageSharp.fixed} />

    <GenericSection
      title="我们的优势"
      desc="葆康——医疗健康行业一站式新媒体运营专家"
      style={{ backgroundColor: '#f0f0f0' }}
    >
      <NewMediaImageList list={populateGatsbyImage(data, advantageData, 'image')} />
    </GenericSection>
  </PageLayout>
}

export const query = graphql`
  query {
    banner: file(relativePath: {eq: "new-media-banner.png"}) {
      childImageSharp {
        fixed(width: 1440, height: 590) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },

    social1: file(relativePath: {eq: "social-media.png"}) {
      childImageSharp {
        fixed(width: 1440, height: 960) {
          ...GatsbyImageSharpFixed_withWebp
        }
      },
    },
    social2: file(relativePath: {eq: "social-section.jpg"}) {
      childImageSharp {
        fixed(width: 1440, height: 960) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },

    image1: file(relativePath: {eq: "img-1.jpg"}) {
      childImageSharp {
        fixed(width: 368, height: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    image2: file(relativePath: {eq: "img-2.jpg"}) {
      childImageSharp {
        fixed(width: 368, height: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
    image3: file(relativePath: {eq: "img-3.jpg"}) {
      childImageSharp {
        fixed(width: 368, height: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    },
  }
`;
