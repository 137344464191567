import React from 'react';
import Image from 'gatsby-image';
import styles from './icon-text-list.module.scss';

function IconItem({ icon, title, desc }) {
  return <div className={styles.item}>
    <div className={styles.icon}><Image fixed={icon} /></div>
    <h4>{title}</h4>
    <p>{desc}</p>
  </div>
}

export default function IconTextList({ list }) {
  return <div className={styles.list}>
    {list.map((item, i) => <IconItem key={i} {...item} />)}
  </div>
}
