import React from 'react';
import Image from 'gatsby-image';
import styles from './new-media-image-list.module.scss';

function ImageItem({ title, desc, image }) {
  return <div className={styles.item}>
    <h4>{title}</h4>
    <p>{desc}</p>
    <div className={styles.image}>
      <Image fixed={image} />
    </div>
  </div>
}

export default function NewMediaImageList({ list }) {
  return <div className={styles.list}>
    {list.map((item, i) => <ImageItem key={i} {...item} />)}
  </div>
}
